
<template>
    <div>
        <div class="modal_header">
            <el-row >
                <el-col :xs="24" :sm="24">
                    <div class="grid-content bg-purple">
                        <div class="title_h">
                           {{ $t('message.update_role') }}
                        </div>
                    </div>
                </el-col>
                <el-col :xs="24" :sm="24">
                    <div class="grid-content bg-purple-light">
                        <el-row class="button_r">
                           <el-button :loading="loadingButton" :disabled="loadingButton ? true : false" @click="submit(true)" type="success">{{ $t('message.save_and_close') }}</el-button>
                           <el-button @click="resetForm('form')">{{ $t('message.close') }}</el-button>
                        </el-row>
                    </div>
                </el-col>
            </el-row>
        </div>

        <el-form ref="form" status-icon :model="form" :rules="rules" :label-position="'right'" label-width="150px" class="aticler_m" >
            <el-col :span="10">
                <el-form-item :label="columns.name.title" prop="name">
                    <el-input v-model="form.name" autocomplete="off"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="10">
                <el-form-item :label="columns.slug.title" prop="slug">
                    <el-input disabled v-model="form.slug" autocomplete="off"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="10">
                <el-form-item :label="$t('message.access_right')">
                    <el-tree
                        :data="dataPermissions"
                        show-checkbox
                        node-key="id"
                        ref="tree"
                        :default-checked-keys="default_checked_permissions"
                        :props="defaultProps">
                    </el-tree>
                </el-form-item>
            </el-col>
        </el-form>
    </div>
</template>
<script>
    import {mapGetters,mapActions} from 'vuex';
    import form from '@/utils/mixins/form';
    export default {
        mixins:[form],
        props:{
            reloadModel: {type:Boolean, required: true},
        },
        data(){
            return {
                defaultProps: {
                    children: 'children',
                    label: 'name'
                },
                default_checked_permissions: []
            }
        },
        watch: {
            reloadModel: {
                handler: function () {
                    if (this.reloadModel){
                        this.loadModel()
                    }
                },
                deep: true
            },
        },
        async mounted(){
            if (this.dataPermissions && this.dataPermissions.length === 0){
                await this.loadParentPermissions()
            }
            this.loadModel();
        },
        computed: {
            ...mapGetters({
                dataPermissions: 'permissions/parent_permissions',
                roles: 'roles/list',
                rules: 'roles/rules',
                model: 'roles/model',
                columns: 'roles/columns',
                permissions: 'roles/permissions'
            })
        },
        methods:{
            ...mapActions({
                save: 'roles/update',
                updateList: 'roles/index',
                loadParentPermissions: 'permissions/parent_permissions'
            }),
            submit(){
                let checkedPermissions = this.$refs.tree.getCheckedNodes();
                let perms = [];
                for (let key in checkedPermissions){
                    if (checkedPermissions.hasOwnProperty(key)){
                        let checkedPermission = checkedPermissions[key];
                        perms.push(checkedPermission.id)
                    }
                }
                this.form['permissions'] = perms;
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.loadingButton = true;
                        this.save(this.form)
                            .then(res => {
                                this.loadingButton = false;
                                this.$alert({success:true,message:res.message});
                                this.parent().listChanged()
                                this.resetForm('form');
                                this.$refs.tree.setCheckedNodes([]);
                            })
                            .catch(err => {
                                this.loadingButton = false;
                                this.$notify({
        title: 'Ошибка',
        type: "error",
        offset: 130,
        message: err.error.message
      });
                            });
                    }
                });
            },
            loadModel(){
                this.default_checked_permissions = [];                
                for (let key in this.permissions){
                    if (this.permissions.hasOwnProperty(key)){
                        this.default_checked_permissions.push(this.permissions[key].id)
                    }
                }
                this.form = JSON.parse( JSON.stringify( this.model ));
                this.$refs.tree.setCheckedKeys(this.default_checked_permissions);
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.$emit('c-close',{drawer: 'drawerUpdate'});
            }
        },
    }
</script>
